import React, { createContext, useReducer } from 'react';

export const Store = createContext();

const initialValue = {
  validationMsg: null,
  toggleState: localStorage.getItem('toggleState')
    ? JSON.parse(localStorage.getItem('toggleState'))
    : null,
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null,
  fields: localStorage.getItem('jasonFiled')
    ? JSON.parse(localStorage.getItem('jasonFiled'))
    : '',
  settings: localStorage.getItem('jasonSetting')
    ? JSON.parse(localStorage.getItem('jasonSetting'))
    : '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'USER_SIGNIN':
      return { ...state, userInfo: action.payload };
    case 'VALIDATION_MSG':
      return { ...state, validationMsg: action.payload };
    case 'USER_SIGNOUT':
      return {
        ...state,
        userInfo: null,
      };
    case 'TOGGLE_BTN':
      return {
        ...state,
        toggleState: action.payload,
      };

    case 'SAVE_FORM_FIELDS':
      return {
        ...state,
        fields: action.payload,
      };
    case 'SAVE_FORM_SETTINGS':
      return {
        ...state,
        settings: action.payload,
      };
    default:
      return state;
  }
};

export default function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialValue);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
