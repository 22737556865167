import React, { useContext, useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HexColorPicker } from 'react-colorful';
//import 'react-colorful/dist/index.css';
import { Store } from '../Store';
export default function IframeEditScreen() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const localFormDataAndcolor = localStorage.getItem('jasonSetting')
    ? JSON.parse(localStorage.getItem('jasonSetting'))
    : {};
  const localFormDataField = localStorage.getItem('jasonFiled')
    ? JSON.parse(localStorage.getItem('jasonFiled'))
    : {};

  const { id } = useParams();

  const { dispatch: ctxDispatch } = useContext(Store);

  const navigate = useNavigate();
  const [fields, setFields] = useState([]);
  const [iframField, setIframField] = useState(
    localFormDataField ? localFormDataField : {}
  );
  const [globalToggle, setGlobalToggle] = useState(true);
  const [selectedColor, setSelectedColor] = useState({
    iframeButtonTextColor: localFormDataAndcolor.iframeButtonTextColor
      ? localFormDataAndcolor.iframeButtonTextColor
      : '#fff',
    iframeButtonBgColor: localFormDataAndcolor.iframeButtonBgColor
      ? localFormDataAndcolor.iframeButtonBgColor
      : '#0d6efd',
    iframeButtonBorderColor: localFormDataAndcolor.iframeButtonBorderColor
      ? localFormDataAndcolor.iframeButtonBorderColor
      : '#fff',
    iframeBgColor: localFormDataAndcolor.iframeBgColor
      ? localFormDataAndcolor.iframeBgColor
      : '#fff',
  });
  const [showColorPicker, setShowColorPicker] = useState({
    iframeButtonTextColor1: false,
    iframeButtonBgColor2: false,
    iframeButtonBorderColor3: false,
    iframeBgColor: false,
  });
  const [formData, setFormData] = useState(
    localStorage.getItem('jasonSetting')
      ? JSON.parse(localStorage.getItem('jasonSetting'))
      : {
          iframeName: 'Iframe-' + Date.now(),
          iframeWidth: '100%',
          iframeHeight: '400px',
          iframeBorder: '1px',
          iframeBgColor: '#fff',
          iframeScrolling: 'Yes',
          iframeAllowfullscreen: '0',
          iframePadding: '10px',
          iframeButtonText: 'Submit',
          iframeButtonWidth: 'auto',
          iframeButtonHeight: 'auto',
          iframeButtonMargin: '6px',
          iframeButtonPadding: '14px',
          iframeButtonBorderWidth: '1px',
          iframeButtonTextColor: '#fff',
          iframeButtonBorderColor: '#fff',
          iframeButtonBgColor: '#0d6efd',
          iframeButtonBorderRadius: '2px',
          iframeButtonBorderFontsize: '16px',
          iframeButtonBorderFontWeight: 'bold',
        }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseField = await axios.get(`${apiBaseUrl}/api/field`);
        const data = responseField.data.fields;
        if (data) {
          setFields(data);
          const response = await axios.get(`${apiBaseUrl}/api/iframe/${id}`);
          const datas = response.data;

          for (const [value] of Object.entries(datas.fields)) {
            if (value == false) {
              setGlobalToggle(false);
            }
          }
          if (Object.keys(localFormDataAndcolor).length == 0) {
            setSelectedColor({
              iframeButtonTextColor: datas.settings.iframeButtonTextColor,
              iframeButtonBgColor: datas.settings.iframeButtonBgColor,
              iframeButtonBorderColor: datas.settings.iframeButtonBorderColor,
              iframeBgColor: datas.settings.iframeBgColor,
            });

            setFormData(datas.settings);
          }
          if (Object.keys(localFormDataField).length == 0) {
            data.forEach((el) => {
              if (!(el.fieldName in datas.fields)) {
                //add el.fieldName if is not exist in data.fields
                datas.fields[el.fieldName] = false;
              }
            });

            setIframField(datas.fields);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`${apiBaseUrl}/api/iframe/${id}`);
  //       const data = response.data;

  //       for (const [key, value] of Object.entries(data.fields)) {
  //         if (value == false) {
  //           setGlobalToggle(false);
  //         }
  //       }
  //       if (Object.keys(localFormDataAndcolor).length == 0) {
  //         setSelectedColor({
  //           iframeButtonTextColor: data.settings.iframeButtonTextColor,
  //           iframeButtonBgColor: data.settings.iframeButtonBgColor,
  //           iframeButtonBorderColor: data.settings.iframeButtonBorderColor,
  //           iframeBgColor: data.settings.iframeBgColor,
  //         });

  //         setFormData(data.settings);
  //       }
  //       if (Object.keys(localFormDataField).length == 0) {
  //         console.log('data.fields...', data.fields);
  //         fields.forEach((el) => {
  //           console.log(`${el.fieldName} in data.fields`);
  //           if (el.fieldName in data.fields) {
  //             console.log(`${el.fieldName} is not exist in data.fields`);
  //             data.fields[el.fieldName] = false;
  //           }
  //         });

  //         console.log('data.fields...', data.fields);
  //         setIframField(data.fields);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const handleColorChange = (color, key) => {
    setSelectedColor({ ...selectedColor, [key]: color });
  };

  const toggleColorPicker = (key) => {
    setShowColorPicker((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleGlobalToggle = () => {
    setGlobalToggle(!globalToggle);
    const updatedIframField = { ...iframField };
    fields.map((el) => {
      for (const [key] of Object.entries(iframField)) {
        if (key == el.fieldName) {
          updatedIframField[key] = !globalToggle;
        }
      }
    });
    setIframField(updatedIframField);
  };

  const handleToggle = (field) => {
    const updatedIframField = { ...iframField };

    for (const [key, value] of Object.entries(updatedIframField)) {
      if (key === field) {
        if (value) setGlobalToggle(false);
        updatedIframField[key] = !value;
      }
    }

    setIframField(updatedIframField);
  };

  useEffect(() => {
    const { _id, createdAt, updatedAt, __v, ...others } = iframField;

    const allValuesTrue = Object.values(others).every(
      (value) => value === true
    );

    if (allValuesTrue) setGlobalToggle(true);
  }, [iframField]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      delete formData._id;
      delete iframField._id;
      const dataPayload = {
        fields: iframField,
        settings: { ...formData, ...selectedColor },
      };

      const response = await axios.post(
        `${apiBaseUrl}/api/iframe/update/${id}`,
        dataPayload
      );
      if (response.status === 200) {
        toast.success('Iframe updated Successfully !', {
          position: 'bottom-right',
          autoclose: 2500,
        });

        localStorage.removeItem('jasonFiled');
        localStorage.removeItem('jasonSetting');
      }
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const checkAndReturn = (field) => {
    for (const [key, value] of Object.entries(iframField)) {
      if (key == field.fieldName) {
        return value;
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePreviewData = () => {
    ctxDispatch({ type: 'SAVE_FORM_FIELDS', payload: iframField });
    ctxDispatch({
      type: 'SAVE_FORM_SETTINGS',
      payload: { ...formData, ...selectedColor },
    });
    const jasonFiled = JSON.stringify(iframField);
    localStorage.setItem('jasonFiled', jasonFiled);
    const jasonSetting = JSON.stringify({ ...formData, ...selectedColor });
    localStorage.setItem('jasonSetting', jasonSetting);

    navigate(`/iframeScreen/${id}/previewIframe`);
  };

  const handlePreviewDataSubmit = async () => {
    try {
      delete formData._id;
      delete iframField._id;

      const dataPayload = {
        fields: iframField,
        settings: { ...formData, ...selectedColor },
      };
      const data = await axios.post(`${apiBaseUrl}/api/preview/savePreview`, {
        dataPayload,
      });
      if (data.status === 200) {
        ctxDispatch({ type: 'SAVE_FORM_FIELDS', payload: data.fields });
        ctxDispatch({
          type: 'SAVE_FORM_SETTINGS',
          payload: data.settings,
        });
        const jasonFiled = JSON.stringify(iframField);
        localStorage.setItem('jasonFiled', jasonFiled);
        const jasonSetting = JSON.stringify({ ...formData, ...selectedColor });
        localStorage.setItem('jasonSetting', jasonSetting);
        const previewId = JSON.stringify(id);
        localStorage.setItem('previewId', previewId);
        navigate('/Preview');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <div>
        <div className="topname d-flex ">
          <InputGroup className="mb-3 setfilds">
            <Form.Control
              size="lg"
              type="text"
              placeholder="Iframe Name"
              name="iframeName"
              value={formData.iframeName}
              onChange={handleInputChange}
            />
          </InputGroup>
          <div className="setfilds-btn d-flex justify-content-end align-items-baseline">
            <Button
              onClick={handleSubmit}
              variant="primary"
              className="mx-2 allBtnBg"
            >
              Update Frame
            </Button>

            {/* <Button onClick={handlePreviewData}>Preview</Button> */}
            <Button onClick={handlePreviewDataSubmit} className="allBtnBg">
              Preview
            </Button>
          </div>
        </div>
        <div className="fieldScreenContainer d-flex w-100 my-3 gap-4 justify-content-center align-item-center projectScreenCard-outer ">
          <Card className="projectScreenCard">
            <Card.Header className="d-flex justify-content-between">
              <p className="px-3">Filed Name</p>
              <p>Show/Hide</p>
            </Card.Header>
            <Card.Body className="text-start allfieldScreenCBody">
              <Form className="px-3" onSubmit={handleSubmit}>
                <Form.Group className="mb-3 d-flex justify-content-end gapClass">
                  <Form.Label className="fw-normal mr-1">Select All</Form.Label>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input allToggleBtn"
                      type="checkbox"
                      id="globalToggle"
                      onClick={handleGlobalToggle}
                      checked={globalToggle}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="globalToggle"
                    ></label>
                  </div>
                </Form.Group>
                {fields.map((field) => (
                  <Form.Group
                    key={field._id}
                    className="mb-3 d-flex justify-content-between"
                  >
                    <Form.Label className="fw-bold">{field.name}</Form.Label>
                    <div key={field._id} className="form-check form-switch">
                      <input
                        className="form-check-input allToggleBtn"
                        type="checkbox"
                        id={`flexSwitchCheck-${field._id}`}
                        onClick={() => handleToggle(field.fieldName)}
                        checked={checkAndReturn(field)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`flexSwitchCheck-${field._id}`}
                      ></label>
                    </div>
                  </Form.Group>
                ))}
              </Form>
            </Card.Body>
          </Card>
          <Card className="projectScreenCard2">
            <Card.Header className="d-flex justify-content-start">
              <p>Configration</p>
            </Card.Header>
            <Card.Body className="d-flex flex-wrap gap-3 ">
              <Card className="projectScreenCard2">
                <Card.Header className="d-flex justify-content-start">
                  <p>Iframe Setting</p>
                </Card.Header>
                <Card.Body className="d-flex flex-wrap gap-3 ">
                  <div className="d-flex tenpx-gap">
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Width
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="setfildsinput"
                        name="iframeWidth"
                        value={formData.iframeWidth}
                        onChange={handleInputChange}
                        placeholder="100%"
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Height
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="setfildsinput"
                        name="iframeHeight"
                        value={formData.iframeHeight}
                        onChange={handleInputChange}
                        placeholder="400px"
                      />
                    </InputGroup>
                  </div>
                  <div className="d-flex tenpx-gap">
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Border
                      </InputGroup.Text>
                      <Form.Control
                        className="setfildsinput"
                        type="text"
                        name="iframeBorder"
                        value={formData.iframeBorder}
                        onChange={handleInputChange}
                        placeholder="2px"
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Scrolling
                      </InputGroup.Text>
                      <Form.Select
                        name="iframeScrolling"
                        className="setfildsinput"
                        value={formData.iframeScrolling}
                        onChange={handleInputChange}
                      >
                        <option value="Yes">Yes</option>
                        <option value="no">No</option>
                      </Form.Select>
                    </InputGroup>
                  </div>
                  <div className="d-flex tenpx-gap">
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Allowfullscreen
                      </InputGroup.Text>
                      <Form.Select
                        className="setfildsinput"
                        name="iframeAllowfullscreen"
                        value={formData.iframeAllowfullscreen}
                        onChange={handleInputChange}
                      >
                        <option value="0">Yes</option>
                        <option value="1">No</option>
                      </Form.Select>
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Padding
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="setfildsinput"
                        name="iframePadding"
                        value={formData.iframePadding}
                        onChange={handleInputChange}
                        placeholder="2px"
                      />
                    </InputGroup>
                  </div>
                  <div
                    className="d-flex tenpx-gap"
                    style={{ flexBasis: '600px' }}
                  >
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Background Color
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="setfildsinput"
                        name="iframeBgColor"
                        value={selectedColor.iframeBgColor}
                        onChange={handleInputChange}
                      />
                      <Button
                        size="sm"
                        style={{
                          background: `${selectedColor.iframeBgColor}`,
                        }}
                        className="setfildsinput"
                        onClick={() => toggleColorPicker('iframeBgColor')}
                      ></Button>
                    </InputGroup>
                    <InputGroup className="mb-3"></InputGroup>
                  </div>

                  {showColorPicker.iframeBgColor && (
                    <HexColorPicker
                      color={selectedColor.iframeBgColor}
                      onChange={(color) =>
                        handleColorChange(color, 'iframeBgColor')
                      }
                    />
                  )}
                </Card.Body>
              </Card>
              <Card className="projectScreenCard2">
                <Card.Header className="d-flex justify-content-start">
                  <p>Button Setting</p>
                </Card.Header>
                <Card.Body className="d-flex flex-wrap gap-3 ">
                  <div className="d-flex tenpx-gap">
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Text
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="setfildsinput"
                        name="iframeButtonText"
                        value={formData.iframeButtonText}
                        onChange={handleInputChange}
                        placeholder="Submit"
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Text Color
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="setfildsinput"
                        name="iframeButtonTextColor"
                        value={selectedColor.iframeButtonTextColor}
                        onChange={handleInputChange}
                      />
                      <Button
                        size="sm"
                        className="setfildsinput"
                        style={{
                          background: `${selectedColor.iframeButtonTextColor}`,
                        }}
                        onClick={() =>
                          toggleColorPicker('iframeButtonTextColor1')
                        }
                      ></Button>
                    </InputGroup>
                  </div>
                  {showColorPicker.iframeButtonTextColor1 && (
                    <HexColorPicker
                      color={selectedColor.iframeButtonTextColor}
                      onChange={(color) =>
                        handleColorChange(color, 'iframeButtonTextColor')
                      }
                    />
                  )}
                  <div className="d-flex tenpx-gap">
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Font size
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="setfildsinput"
                        name="iframeButtonBorderFontsize"
                        value={formData.iframeButtonBorderFontsize}
                        onChange={handleInputChange}
                        placeholder="16px"
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Font Weight
                      </InputGroup.Text>
                      <Form.Select
                        type="text"
                        className="setfildsinput"
                        name="iframeButtonBorderFontWeight"
                        value={formData.iframeButtonBorderFontWeight}
                        onChange={handleInputChange}
                      >
                        <option value="normal">normal</option>
                        <option value="bold">bold</option>
                        <option value="bolder">bolder</option>
                        <option value="lighter">lighter</option>
                      </Form.Select>
                    </InputGroup>
                  </div>
                  <div className="d-flex tenpx-gap">
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Width
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="setfildsinput"
                        name="iframeButtonWidth"
                        value={formData.iframeButtonWidth}
                        onChange={handleInputChange}
                        placeholder="60px Or Auto"
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Height
                      </InputGroup.Text>
                      <Form.Control
                        className="setfildsinput"
                        type="text"
                        name="iframeButtonHeight"
                        value={formData.iframeButtonHeight}
                        onChange={handleInputChange}
                        placeholder="60px Or Auto"
                      />
                    </InputGroup>
                  </div>
                  <div className="d-flex tenpx-gap">
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Margin
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="setfildsinput"
                        name="iframeButtonMargin"
                        value={formData.iframeButtonMargin}
                        onChange={handleInputChange}
                        placeholder="10px"
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Padding
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="setfildsinput"
                        name="iframeButtonPadding"
                        value={formData.iframeButtonPadding}
                        onChange={handleInputChange}
                        placeholder="10px"
                      />
                    </InputGroup>
                  </div>
                  <div className="d-flex tenpx-gap">
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Border Width
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="setfildsinput"
                        name="iframeButtonBorderWidth"
                        value={formData.iframeButtonBorderWidth}
                        onChange={handleInputChange}
                        placeholder="2px"
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Border Radius
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="iframeButtonBorderRadius"
                        className="setfildsinput"
                        value={formData.iframeButtonBorderRadius}
                        onChange={handleInputChange}
                        placeholder="2px"
                      />
                    </InputGroup>
                  </div>
                  <div className="d-flex tenpx-gap">
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Border Color
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="setfildsinput"
                        name="iframeButtonBorderColor"
                        value={selectedColor.iframeButtonBorderColor}
                        onChange={handleInputChange}
                      />
                      <Button
                        size="sm"
                        className="setfildsinput"
                        style={{
                          background: `${selectedColor.iframeButtonBorderColor}`,
                        }}
                        onClick={() =>
                          toggleColorPicker('iframeButtonBorderColor3')
                        }
                      ></Button>
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text
                        id="inputGroup-sizing-default"
                        className="setfildsinput"
                      >
                        Background Color
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        className="setfildsinput"
                        name="iframeButtonBgColor"
                        value={selectedColor.iframeButtonBgColor}
                        onChange={handleInputChange}
                      />
                      <Button
                        size="sm"
                        style={{
                          background: `${selectedColor.iframeButtonBgColor}`,
                        }}
                        className="setfildsinput"
                        onClick={() =>
                          toggleColorPicker('iframeButtonBgColor2')
                        }
                      ></Button>
                    </InputGroup>
                  </div>
                  <div className="d-flex tenpx-gap">
                    {showColorPicker.iframeButtonBorderColor3 && (
                      <HexColorPicker
                        color={selectedColor.iframeButtonBorderColor}
                        onChange={(color) =>
                          handleColorChange(color, 'iframeButtonBorderColor')
                        }
                      />
                    )}

                    {showColorPicker.iframeButtonBgColor2 && (
                      <HexColorPicker
                        color={selectedColor.iframeButtonBgColor}
                        onChange={(color) =>
                          handleColorChange(color, 'iframeButtonBgColor')
                        }
                      />
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}
