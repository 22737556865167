import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { Store } from '../Store';
import IframeEditScreen from './IframeEditScreen';
import PreviewiframeEdit from './previewiframeEdit';

export default function PreviewScreen() {
  const { state } = useContext(Store);
  const { settings, fields } = state;

  const { id } = useParams();

  console.log('id 123', id);
  if (id == 'undefined') {
    console.log('id 12345', id);
  }
  if (id == undefined) {
    console.log('id 123456', id);
  }
  const styles = {
    border: ` ${settings.iframeBorder} solid black`,
    scrolling: settings.iframeScrolling,
    padding: settings.iframePadding,

    backgroundColor: settings.iframeBgColor,
  };
  return (
    <div className="w-100">
      <div className="d-flex align-items-left m-3">
        <Link
          className="previewBtn"
          to={id == undefined ? `/iframeScreen` : `/iframeScreen/${id}`}
        >
          <Button variant="primary" className="mx-2 px-5">
            Back
          </Button>
        </Link>
      </div>
      <div className="p-2">
        <iframe
          style={styles}
          className="iframeDesign"
          title="myFrame"
          id="custom-iframe"
          // src={`http://localhost:3000/iframeScreen/${
          //   id != 'undefined' ? `${id}` : ``
          // }/previewIframe/edit`}
          src={`https://jasonfront.onrender.com/iframeScreen/${
            id != 'undefined' ? `${id}` : ``
          }/previewIframe/edit`}
          scrolling={settings.iframeScrolling}
          allowFullScreen={settings.allowFullScreen}
          height={settings.iframeHeight}
          width={settings.iframeWidth}
          frameborder="1"
        ></iframe>
      </div>
    </div>
  );
}
