import React, { useContext, useState, useEffect } from 'react';
import { Store } from '../Store';

function PreviewiframeEdit() {
  const { state } = useContext(Store);
  const { fields, settings } = state;
  console.log(fields);
  const { first_name } = fields;
  console.log(first_name);
  console.log('data', fields, settings);
  console.log(first_name);
  const [inputValues, setInputValues] = useState({
    first_name: '',
    address1: '',
    city: 'Select City',
    zip: '',
    phone2: '',
    dob: '',
    posturl: '',
    affid: '',
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    fetch('http://localhost:3000/api/formData', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(inputValues),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.status === 'ACCEPTED') {
          // Redirect to thankYou.html
          window.location.href = 'http://localhost:3000/thankYou.html';
        } else {
          // Redirect to rejection.html
          window.location.href = 'http://localhost:3000/rejection.html';
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };
  useEffect(() => {
    const elementToRemove = document.querySelector('.sidebar');
    elementToRemove.style.display = 'none';
    const navbarToRemove = document.querySelector('.navbar');
    navbarToRemove.style.display = 'none';
  }, []);

  const styles = {
    borderRadius: settings.iframeButtonBorderRadius,
    backgroundColor: settings.iframeButtonBgColor,
    fontSize: settings.iframeButtonBorderFontsize,
    fontWeight: settings.iframeButtonBorderFontWeight,
    border: `${settings.iframeButtonBorderWidth} solid ${settings.iframeButtonBorderColor}`,
    height: settings.iframeButtonHeight,
    margin: settings.iframeButtonMargin,
    padding: settings.iframeButtonPadding,
    color: settings.iframeButtonTextColor,
    width: settings.iframeButtonWidth,
    scrolling: settings.iframeScrolling,
    padding: settings.iframePadding,
  };
  return (
    <div>
      <div
        className="form-container"
        style={{ backgroundColor: settings.iframeBgColor }}
      >
        <form id="frameForm" onSubmit={handleSubmit}>
          {Object.entries(fields).map(([key, value]) => {
            console.log(key, value); // Log key and value to the console
            return (
              value === true && (
                <input
                  key={key}
                  className="ginput"
                  type="text"
                  placeholder={key} // Use the key as the placeholder text
                  name={key}
                  value={inputValues[key]}
                  onChange={handleInputChange}
                />
              )
            );
          })}
          <div className="btncontainer">
            <button style={styles} className="iframeButton" type="submit">
              {settings.iframeButtonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PreviewiframeEdit;
