import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

export default function Preview() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [frameJs, setFormJs] = useState();
  const [loading, setLocading] = useState(true);
  const [style, setStyle] = useState({});
  const previewId = localStorage.getItem('previewId')
    ? JSON.parse(localStorage.getItem('previewId'))
    : '';
  useEffect(() => {
    setLocading(false);
  }, []);
  const handlePreviewId = () => {
    if (previewId != '') {
      localStorage.removeItem('previewId');
      navigate(`/iframeScreen/${previewId}`);
    } else {
      navigate('/iframeScreen');
    }
  };
  const onframeloadHandle = () => {
    try {
      const fetchJs = async () => {
        const { data } = await axios.get(
          `${apiBaseUrl}/api/preview/getPreviewJs`
        );
        const iframe = document.getElementById('custom-iframe');
        console.log('...data ', typeof data, data);
        const datas = {
          width: data.iframeWidth,
          height: data.iframeHeight,
          border: data.iframeBorder + ' solid #ccc',
          padding: data.iframePadding,
          scrolling: data.iframeScrolling,
          allowfullscreen: data.iframeAllowfullscreen,
        };
        iframe.scrolling = data.iframeScrolling;
        iframe.allowfullscreen = data.iframeAllowfullscreen;

        setStyle(datas);
        setLocading(false);
      };
      fetchJs();
    } catch (error) {
      console.log('Err', error);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-left m-3">
        <Button
          variant="primary"
          onClick={handlePreviewId}
          className="mx-2 px-5 allBtnBg"
        >
          Back
        </Button>
      </div>
      {loading ? (
        <div>
          <img
            style={{ width: '100px' }}
            src={`https://jasonfront.onrender.com/loader.gif`}
            alt="Loader"
          />
        </div>
      ) : (
        <iframe
          style={style}
          src={`${apiBaseUrl}/api/preview/getPreviewHtml`}
          id="custom-iframe"
          onLoad={onframeloadHandle}
          title="ifreamePreview"
        ></iframe>
      )}
    </div>
  );
}
