import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Grid } from '@mui/material';
import { AiFillDelete } from 'react-icons/ai';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import { Link } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FATCH_REQUEST':
      return { ...state, loading: true };
    case 'FATCH_SUCCESS':
      return { ...state, iframData: action.payload, loading: false };
    case 'FATCH_ERROR':
      return { ...state, error: action.payload, loading: false };

    case 'DELETE_SUCCESS':
      return { ...state, successDelete: action.payload };

    case 'DELETE_RESET':
      return { ...state, successDelete: false };

    case 'UPDATE_SUCCESS':
      return { ...state, successUpdate: action.payload };

    case 'UPDATE_RESET':
      return { ...state, successUpdate: false };

    default:
      return state;
  }
};

const columns = [
  {
    field: 'iframeName',
    headerName: 'IframeName',
    width: 200,
  },
  { field: '_id', headerName: 'ID', width: 250 },
];

export default function AdminAgentListScreen() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  localStorage.removeItem('previewId');
  localStorage.removeItem('jasonFiled');
  localStorage.removeItem('jasonSetting');
  console.log('apiBaseUrl ', apiBaseUrl, process.env);
  const { state } = React.useContext(Store);
  const { userInfo } = state;
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [copyMessage, setCopyMessage] = React.useState({});
  const [{ loading, error, iframData, successDelete }, dispatch] =
    React.useReducer(reducer, {
      loading: true,
      error: '',
      iframData: [],
      successDelete: false,
    });

  React.useEffect(() => {
    const clearCopyMessagesTimeouts = {};
    for (const rowIndex in copyMessage) {
      clearCopyMessagesTimeouts[rowIndex] = setTimeout(() => {
        const newCopyMessages = { ...copyMessage };
        delete newCopyMessages[rowIndex];
        setCopyMessage(newCopyMessages);
      }, 500);
    }

    return () => {
      for (const timeoutId in clearCopyMessagesTimeouts) {
        clearTimeout(clearCopyMessagesTimeouts[timeoutId]);
      }
    };
  }, [copyMessage]);

  React.useEffect(() => {
    const FatchiframData = async () => {
      try {
        dispatch('FATCH_REQUEST');
        const response = await axios.get(`${apiBaseUrl}/api/iframe`);
        const datas = response.data;
        console.log('datas ', datas);
        const rowData = datas.map((items) => {
          return {
            ...items.field,
            _id: items.field._id,
            iframeName: items.setting.iframeName,
            iframeDir: items.setting.iframeDir,
          };
        });
        dispatch({ type: 'FATCH_SUCCESS', payload: rowData });
      } catch (error) {
        console.log(error);
      }
    };
    if (successDelete) {
      dispatch({ type: 'DELETE_RESET' });
    } else {
      FatchiframData();
    }
  }, [successDelete]);

  const deleteHandle = async (iframeid) => {
    if (window.confirm('Are you sure to delete?')) {
      try {
        const response = await axios.delete(
          `${apiBaseUrl}/api/iframe/${iframeid}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );

        if (response.status == 200) {
          toast.success('Iframe deleted successfully!', {
            position: 'bottom-right',
            autoclose: 2500,
          });
          dispatch({
            type: 'DELETE_SUCCESS',
            payload: true,
          });
        } else {
          toast.error('Failed to delete Iframe.', {
            position: 'bottom-right',
            autoclose: 2500,
          });
        }
      } catch (error) {
        console.error(error);
        toast.error('An error occurred while deleting Iframe.', {
          position: 'bottom-right',
          autoclose: 2500,
        });
      }
    }
  };

  const copyHandle = (rowIndex) => {
    console.log('rowIndex', rowIndex);
    const iframeCode = `<iframe id="custom-iframe" src="https://jasonapi.onrender.com/api/iframe/frame/${rowIndex}" scrolling="no" frameborder="0"></iframe>`;
    const scriptCode = `<script src="https://jasonapi.onrender.com/api/iframe/script/${rowIndex}"></script>`;

    const fullCode = `${iframeCode}\n${scriptCode}`;
    const textArea = document.createElement('textarea');

    textArea.value = fullCode;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      const newCopyMessages = { ...copyMessage };
      newCopyMessages[rowIndex] = 'Copied to clipboard';
      setCopyMessage(newCopyMessages);
    } catch (err) {
      const newCopyMessages = { ...copyMessage };
      newCopyMessages[rowIndex] = 'Copy failed';
      setCopyMessage(newCopyMessages);
    } finally {
      document.body.removeChild(textArea);
    }
  };

  return (
    <>
      <div className="dashboardWrapper">
        {/* <ToastContainer position="bottom-center" limit={1} /> */}
        {loading ? (
          <div>
            <img
              style={{ width: '100px' }}
              src={`https://jasonfront.onrender.com/loader.gif`}
              alt="Loader"
            />
          </div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <>
            <div>
              <Link to="/iframeScreen" className="text-decoration-none">
                <Button variant="outlined" className=" m-2 d-flex allBtnBg">
                  Add Iframe
                </Button>
              </Link>
              <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                  className="tableBg mx-2"
                  rows={iframData}
                  columns={[
                    ...columns,
                    {
                      field: 'action',
                      headerName: 'Action',
                      width: 550,
                      sortable: false,
                      renderCell: (params) => {
                        const rowIndex = params.row._id;
                        return (
                          <>
                            <Grid className="d-flex" item xs={8}>
                              <Link to={`/iframeScreen/${params.row._id}`}>
                                <Button
                                  variant="contained"
                                  className="mx-2 tableEditbtn"
                                >
                                  Edit
                                </Button>
                              </Link>

                              <Button
                                variant="outlined"
                                className="mx-2 tableDeletebtn"
                                onClick={() => deleteHandle(params.row._id)}
                                startIcon={<AiFillDelete />}
                              >
                                Delete
                              </Button>
                            </Grid>
                            <Grid className="d-flex">
                              <Button
                                variant="outlined"
                                className="mx-2 tableDeletebtn"
                                onClick={() =>
                                  copyHandle(rowIndex, params.row.iframeDir)
                                }
                                startIcon={<ContentCopyIcon />}
                              >
                                Copy
                              </Button>
                              {copyMessage[rowIndex] && (
                                <div className="copieddata">
                                  <p className="">{copyMessage[rowIndex]}</p>
                                </div>
                              )}
                            </Grid>
                          </>
                        );
                      },
                    },
                  ]}
                  getRowId={(row) => row._id}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </Box>
            </div>
          </>
        )}
      </div>
    </>
  );
}
