import React, { useContext, useEffect, useState } from 'react';
import { AiFillSetting, AiFillHome } from 'react-icons/ai';
import { MdLogout } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Store } from '../Store';
import { ImCross } from 'react-icons/im';
import { CgProfile } from 'react-icons/cg';

function Sidebar({ sidebarVisible, setSidebarVisible }) {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  console.log(userInfo.role);
  const [isSmallScreen, setIsSmallScreen] = useState(true);

  const handlSmallScreeneClick = () => {
    if (isSmallScreen) {
      setSidebarVisible(!sidebarVisible);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1179);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const signoutHandler = () => {
    const userConfirm = window.confirm('Are you sure you want to logout?');
    if (userConfirm) {
      ctxDispatch({ type: 'USER_SIGNOUT' });
      localStorage.removeItem('userInfo');
      localStorage.removeItem('jasonFiled');
      localStorage.removeItem('jasonSetting');
      localStorage.removeItem('formfields');
      window.location.href = '/';
    }
  };
  const handleResponsiveSidebarVisable = () => {
    setSidebarVisible(!sidebarVisible);
  };
  return (
    <div className={`sidebar ${sidebarVisible ? 'visible' : ''} `}>
      <div className="blank-box"></div>
      <ImCross
        className="sidebarCrossBtn"
        onClick={handleResponsiveSidebarVisable}
      />

      <ul className="dash-list ">
        <Link
          to="/dashboard"
          className="text-decoration-none"
          onClick={handlSmallScreeneClick}
        >
          <li>
            <AiFillHome className="me-3 fs-5" />
            Dashboard
          </li>
        </Link>
        <Link
          to="/allfields"
          className="text-decoration-none"
          onClick={handlSmallScreeneClick}
        >
          <li>
            <AiFillSetting className="me-3 fs-5" />
            Fields
          </li>
        </Link>
        <Link
          href="#"
          className="text-decoration-none profileNavShowSmallScreen"
        >
          <li>
            <CgProfile className="fs-4 admin-btn-logo me-3 fs-5" />
            Profile
          </li>
        </Link>
        <Link
          to="#Logout"
          onClick={signoutHandler}
          className="text-decoration-none"
        >
          <li>
            <MdLogout className="me-3 fs-5" />
            Logout
          </li>
        </Link>
      </ul>
    </div>
  );
}

export default Sidebar;
