import './App.css';
import { Container, Form, InputGroup, Nav, Navbar } from 'react-bootstrap';
import { AiOutlineAlignLeft } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { Store } from './Store';
import Login from './screen/Login';
import Dashboard from './screen/Dashboard';
import Sidebar from './component/Sidebar';
import { useContext, useState, useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import IframeScreen from './screen/IframeScreen';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IframeEditScreen from './screen/IframeEditScreen';
import ProtectedRoute from './component/ProtectedRoute';
import AllFields from './screen/AllFields';
import PreviewIframeEditScreen from './screen/previewiframeEdit';
import PreviewScreen from './screen/PreviewScreen';
import Preview from './screen/Preview';

function App() {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { toggleState, userInfo } = state;
  const theme = toggleState ? 'dark' : 'light';

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const unloadFlag = useRef(false);
  const clearLocalStorage = () => {
    //localStorage.removeItem('userInfo');
    localStorage.removeItem('jasonFiled');
    localStorage.removeItem('jasonSetting');
    localStorage.removeItem('formfields');
    localStorage.removeItem('previewId');
  };

  // Attach the beforeunload event listener when the component mounts
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!unloadFlag.current) {
        // Set a flag indicating that the window is being closed
        unloadFlag.current = true;

        // Cancel the event to prevent the browser from closing immediately
        event.preventDefault();
        // You can also provide a message to the user
        event.returnValue = 'Are you sure you want to leave?';

        // Clear local storage data
        clearLocalStorage();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      // Remove the event listener
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Reset the unloadFlag when the component unmounts (e.g., during a page reload)
  useEffect(() => {
    return () => {
      unloadFlag.current = false;
    };
  }, []);
  return (
    <div className={`App ${theme}`}>
      <BrowserRouter>
        <ToastContainer position="bottom-center" limit={1} />
        <div>
          <Container fluid className="px-0">
            <div className="d-flex ">
              {userInfo ? (
                <Sidebar
                  sidebarVisible={sidebarVisible}
                  setSidebarVisible={setSidebarVisible}
                />
              ) : null}

              <div className="px-0 w-100">
                {userInfo ? (
                  <>
                    <Navbar expand="lg" className=" admin-navbar">
                      <Container fluid>
                        <div
                          className="p-2 me-3 fs-5 admin-btn-logo"
                          onClick={toggleSidebar}
                        >
                          <AiOutlineAlignLeft />
                        </div>

                        {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
                        <Navbar.Collapse
                          className="justify-content-end"
                          id="navbarScroll"
                        >
                          <Nav
                            className="gap-3"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                          >
                            <Nav.Link
                              href="#"
                              className="profileHideNavSmallScreen"
                            >
                              <CgProfile className="fs-4 admin-btn-logo " />
                            </Nav.Link>
                          </Nav>
                        </Navbar.Collapse>
                      </Container>
                    </Navbar>
                  </>
                ) : (
                  ''
                )}

                <main>
                  <div>
                    <Routes>
                      <Route path="/" element={<Login />} />
                      <Route
                        path="/dashboard"
                        element={
                          <ProtectedRoute>
                            <Dashboard />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/allfields"
                        element={
                          <ProtectedRoute>
                            <AllFields />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/iframeScreen"
                        element={
                          <ProtectedRoute>
                            <IframeScreen />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/iframeScreen/:id"
                        element={
                          <ProtectedRoute>
                            <IframeEditScreen />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/iframeScreen/:id/previewIframe"
                        element={
                          <ProtectedRoute>
                            <PreviewScreen />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/iframeScreen/previewIframe"
                        element={
                          <ProtectedRoute>
                            <PreviewScreen />
                          </ProtectedRoute>
                        }
                      />
                      <Route />
                      <Route
                        path="/iframeScreen/:id/previewIframe/edit"
                        element={
                          <ProtectedRoute>
                            <PreviewIframeEditScreen />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/preview"
                        element={
                          <ProtectedRoute>
                            <Preview />
                          </ProtectedRoute>
                        }
                      />
                    </Routes>
                  </div>
                </main>
              </div>
            </div>
          </Container>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
