import React, { useContext, useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { BiEditAlt } from 'react-icons/bi';
import { ImCross } from 'react-icons/im';
import { Store } from '../Store';

export default function IframeScreen() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  localStorage.removeItem('previewId');
  localStorage.removeItem('jasonFiled');
  localStorage.removeItem('jasonSetting');

  const { state, dispatch: ctxDispatch } = useContext(Store);

  const [field, setField] = useState([]);
  const [addfield, setAddField] = useState({
    name: '',
    fieldName: '',
    fieldTag: '',
    fieldType: '',
    fieldStatus: true,
    placeholder: '',
  });
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [modelData, setIsModelData] = useState({});
  const handleCloseRow = () => {
    setIsModelOpen(false);
    setIsModelData({});
  };
  const [inputFields, setInputFields] = useState([{ optionName: '' }]);
  const handleSeletInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    console.log('Values ', values);
    setInputFields(values);
  };

  //Used For Seleted TAG
  const handleAddFields = () => {
    setInputFields([...inputFields, { optionName: '' }]);
  };
  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    if (values.length > 1) {
      values.splice(index, 1);
      setInputFields(values);
    }
  };

  const handleDeleteField = async (id) => {
    console.log('   id ', id);

    try {
      const userConfirm = window.confirm(
        'Are you sure to want to delete this field?'
      );
      if (userConfirm) {
        const response = await axios.delete(`${apiBaseUrl}/api/field/${id}`);
        if (response.status === 200) {
          toast.success('Field deleted Successfully !', {
            position: 'bottom-right',
            autoclose: 2500,
          });
          setField(response.data.fields);
        }
      }
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const handleEditField = async (id) => {
    console.log('   id ', id);
    const editField = field.find((el) => el._id == id);
    console.log('editField ', editField);
    if (editField.fieldTag == 'select') {
      setInputFields(editField.fieldOptions);
    }
    setIsModelData(editField);
    setIsModelOpen(true);
  };
  const handleModelSubmit = async () => {
    console.log('modelData ', modelData);
    try {
      const payload = { ...modelData, fieldOptions: inputFields };
      const response = await axios.put(
        `${apiBaseUrl}/api/field/editField/${modelData._id}`,
        payload
      );
      if (response.status === 200) {
        toast.success('Field updated Successfully !', {
          position: 'bottom-right',
          autoclose: 2500,
        });
        setField(response.data.fields);
        setIsModelOpen(false);
        setInputFields([]);
      }
    } catch (error) {
      console.error('API Error:', error);
    }
  };
  const handleModelInputChange = (e) => {
    const { name, value } = e.target;

    // const FieldValue = value.trim().toLowerCase().replace(/ /g, '_');
    if (name == 'name') {
      setIsModelData({
        ...modelData,
        [name]: value,
      });
    } else {
      setIsModelData({
        ...modelData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/api/field`);
        const data = response.data.fields;
        setField(data);
        console.log('dataaa ::', data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const FieldValue = value.trim().toLowerCase().replace(/ /g, '_');
    if (name == 'name') {
      setAddField({
        ...addfield,
        [name]: value,
        fieldName: FieldValue,
      });
    } else {
      setAddField({
        ...addfield,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('   addfield ', addfield);
    try {
      const payload = { ...addfield, fieldOptions: inputFields };
      console.log('Payload ', payload);
      const response = await axios.post(
        `${apiBaseUrl}/api/field/addField`,
        payload
      );
      if (response.status === 200) {
        toast.success('Field added Successfully !', {
          position: 'bottom-right',
          autoclose: 2500,
        });
        console.log(response);
        setField(response.data.fields);
        setInputFields([{ optionName: '' }]);
        setAddField({
          name: '',
          fieldName: '',
          fieldTag: '',
          fieldType: '',
          fieldStatus: true,
          placeholder: '',
        });
      }
    } catch (error) {
      console.error('API Error:', error);
    }
  };
  return (
    <div>
      <div>
        <h3 className="text-start mt-4 w-90">Field Setting</h3>
        <div className="fieldScreenContainer d-flex w-100 my-3 gap-4 justify-content-center align-item-center projectScreenCard-outer ">
          <Card className="projectScreenCard">
            <Card.Header className="d-flex justify-content-between">
              <p className="px-3">Existing Fields</p>
            </Card.Header>
            <Card.Body className="text-start allfieldScreenCBody">
              <Form className="px-3">
                {field.map((field) => (
                  <Form.Group
                    key={field._id}
                    className="mb-3 d-flex justify-content-between"
                  >
                    <Form.Label className="fw-bold">{field.name}</Form.Label>
                    <div>
                      <BiEditAlt
                        className="svg-add-remove-icon"
                        onClick={() => handleEditField(field._id)}
                        style={{ cursor: 'pointer' }}
                      />
                      <RiDeleteBin5Line
                        className="svg-add-remove-icon"
                        onClick={() => handleDeleteField(field._id)}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </Form.Group>
                ))}
              </Form>
            </Card.Body>
          </Card>
          <Card className="projectScreenCard2">
            <Card.Header className="d-flex justify-content-start">
              <p>Add New Field</p>
            </Card.Header>
            <Card.Body className="d-flex flex-wrap gap-3 ">
              <InputGroup className="mb-3">
                <InputGroup.Text
                  id="inputGroup-sizing-default"
                  className="label-w"
                >
                  Field Name
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="name"
                  value={addfield.name}
                  onChange={handleInputChange}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text
                  id="inputGroup-sizing-default"
                  className="label-w"
                >
                  Field Tag
                </InputGroup.Text>
                <Form.Select
                  name="fieldTag"
                  value={addfield.fieldTag}
                  onChange={handleInputChange}
                >
                  <option value="input">Select</option>
                  <option value="input">Input Tag</option>
                  <option value="select">Dropdown Tag</option>
                </Form.Select>
              </InputGroup>
              {addfield.fieldTag === 'input' && (
                <InputGroup className="mb-3">
                  <InputGroup.Text
                    id="inputGroup-sizing-default"
                    className="label-w"
                  >
                    Field Type
                  </InputGroup.Text>
                  <Form.Select
                    name="fieldType"
                    value={addfield.fieldType}
                    onChange={handleInputChange}
                  >
                    <option value="text">Text</option>
                    <option value="email">Email</option>
                    <option value="tel">Phone</option>
                    <option value="password">Password</option>
                  </Form.Select>
                </InputGroup>
              )}
              {addfield.fieldTag === 'select' &&
                inputFields.map((inputField, index) => (
                  <InputGroup key={index} className="mb-3">
                    <InputGroup.Text
                      id="inputGroup-sizing-default"
                      className="label-w"
                    >
                      Option
                    </InputGroup.Text>
                    <Form.Control
                      className="mr-gs-5"
                      name="optionName"
                      label="optionName"
                      variant="filled"
                      value={inputField.firstName}
                      onChange={(event) => handleSeletInput(index, event)}
                    />
                    <AiOutlineMinusCircle
                      className="svg-add-remove-icon remove"
                      onClick={() => handleRemoveFields(index)}
                    />
                    <AiOutlinePlusCircle
                      className="svg-add-remove-icon add"
                      onClick={() => handleAddFields()}
                    />
                  </InputGroup>
                ))}
              <InputGroup className="mb-3">
                <InputGroup.Text
                  id="inputGroup-sizing-default"
                  className="label-w"
                >
                  fieldStatus
                </InputGroup.Text>
                <Form.Select
                  name="fieldStatus"
                  value={addfield.fieldStatus}
                  onChange={handleInputChange}
                >
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Form.Select>
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text
                  id="inputGroup-sizing-default"
                  className="label-w"
                >
                  Placeholder
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="placeholder"
                  value={addfield.placeholder}
                  onChange={handleInputChange}
                />
              </InputGroup>
              <Button
                onClick={handleSubmit}
                variant="primary"
                className="allBtnBg"
              >
                Submit
              </Button>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Modal open={isModelOpen} onClose={handleCloseRow}>
        <Box
          className="modelBg"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Form onSubmit={handleSubmit}>
            <ImCross
              color="black"
              className="formcrossbtn"
              onClick={handleCloseRow}
            />

            <h4 className="d-flex justify-content-center">Edit Field</h4>

            <InputGroup className="mb-3">
              <InputGroup.Text
                id="inputGroup-sizing-default"
                className="label-w"
              >
                Field Name
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="name"
                value={modelData.name}
                onChange={handleModelInputChange}
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text
                id="inputGroup-sizing-default"
                className="label-w"
              >
                Field Tag
              </InputGroup.Text>
              <Form.Select
                name="fieldTag"
                value={modelData.fieldTag}
                onChange={handleModelInputChange}
              >
                <option value="input">Select</option>
                <option
                  value="input"
                  {...(modelData.fieldTag == 'input' ? 'selected' : '')}
                >
                  Input Tag
                </option>
                <option
                  value="select"
                  {...(modelData.fieldTag == 'select' ? 'selected' : '')}
                >
                  Dropdown Tag
                </option>
              </Form.Select>
            </InputGroup>
            {addfield.fieldTag === 'input' && (
              <InputGroup className="mb-3">
                <InputGroup.Text
                  id="inputGroup-sizing-default"
                  className="label-w"
                >
                  Field Type
                </InputGroup.Text>
                <Form.Select
                  name="fieldType"
                  value={modelData.fieldType}
                  onChange={handleModelInputChange}
                >
                  <option value="text">Text</option>
                  <option value="email">Email</option>
                  <option value="tel">Phone</option>
                  <option value="password">Password</option>
                </Form.Select>
              </InputGroup>
            )}
            {modelData.fieldTag === 'select' &&
              inputFields.map((inputField, index) => (
                <InputGroup key={index} className="mb-3">
                  <InputGroup.Text
                    id="inputGroup-sizing-default"
                    className="label-w"
                  >
                    Option
                  </InputGroup.Text>
                  <Form.Control
                    className="mr-gs-5"
                    name="optionName"
                    label="optionName"
                    variant="filled"
                    value={inputField.optionName}
                    onChange={(event) => handleSeletInput(index, event)}
                  />
                  <AiOutlineMinusCircle
                    className="svg-add-remove-icon remove"
                    onClick={() => handleRemoveFields(index)}
                  />
                  <AiOutlinePlusCircle
                    className="svg-add-remove-icon add"
                    onClick={() => handleAddFields()}
                  />
                </InputGroup>
              ))}
            <InputGroup className="mb-3">
              <InputGroup.Text
                id="inputGroup-sizing-default"
                className="label-w"
              >
                Field Status
              </InputGroup.Text>
              <Form.Select
                name="fieldStatus"
                value={modelData.fieldStatus}
                onChange={handleModelInputChange}
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </Form.Select>
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text
                id="inputGroup-sizing-default"
                className="label-w"
              >
                Placeholder
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="placeholder"
                value={modelData.placeholder}
                onChange={handleModelInputChange}
              />
            </InputGroup>
            <Button
              onClick={handleModelSubmit}
              variant="primary"
              className="allBtnBg"
            >
              Submit
            </Button>
          </Form>
        </Box>
      </Modal>
    </div>
  );
}
